import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import { handleContentAccessibility } from "../../utility/accessibility";

const Faq = ({ data }) => {
  const { title, sectionTitle } = data;

  const [accordionMap, setAccordionMap] = useState(
    sectionTitle?.reduce((res, item, i) => {
      res[i] = item?.content?.accordionSection?.value[0] === "open";
      item?.content?.question?.map((subitem, j) => {
        return (res[`${i}-${j}`] = subitem?.content?.accordionQuestion?.value[0] === "open");
      });
      return res;
    }, {})
  );

  return (
    <section className="section faq wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <h1 className="section__title text-center">{title.value}</h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <ul className="faq__accordion">
              {sectionTitle?.map((faq_section, i) => (
                <li className="faq__item" key={i}>
                  <button
                    className="faq__question"
                    onClick={() => setAccordionMap((map) => ({ ...map, [i]: !map[i] }))}
                    value={i}
                  >
                    <span
                      className={classNames("faq__icon", accordionMap[i] && "faq__icon--open")}
                    />
                    <span>{faq_section.value}</span>
                  </button>
                  <div
                    className={classNames("faq__content", accordionMap[i] && "faq__content--open")}
                  >
                    <ul className="faq__accordion faq__accordion--secondary">
                      {faq_section.content?.question?.map((faq_item, j) => (
                        <li className="faq__item faq__item--secondary" key={j}>
                          <button
                            className="faq__question faq__question--secondary"
                            onClick={() =>
                              setAccordionMap((map) => ({
                                ...map,
                                [`${i}-${j}`]: !map[`${i}-${j}`],
                              }))
                            }
                          >
                            <span
                              className={classNames(
                                "faq__icon faq__icon--secondary",
                                accordionMap[`${i}-${j}`] && "faq__icon--open"
                              )}
                            />
                            <span>{faq_item.value}</span>
                          </button>
                          <div
                            className={classNames(
                              "faq__content",
                              accordionMap[`${i}-${j}`] && "faq__content--open"
                            )}
                          >
                            <div className="faq__answer">
                              {handleContentAccessibility(faq_item.content?.answer?.value)}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Faq);
export const fragment = graphql`
  fragment FaqFragment on LiferayFaq {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    sectionTitle {
      value
      content {
        question {
          value
          content {
            answer {
              value
            }
            accordionQuestion {
              value
            }
          }
        }
        accordionSection {
          value
        }
        content {
          value
        }
      }
    }
  }
`;

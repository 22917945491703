import React from "react";
import { graphql } from "gatsby";
import NetReviewReviews from "../elements/netreviews-review";
import withPreview from "../../utility/with-preview";

const RecensioniVerificateReviews = ({ data }) => {
  const { showProductReviews } = data;
  const reviewParams = showProductReviews?.content;
  return (
    <section className="section">
      <div className="wrapper-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section__title">Cosa dicono della nostra offerta</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <NetReviewReviews
                dataProductId={reviewParams?.dataProductId?.value}
                dataProductUrl={reviewParams?.dataProductUrl?.value}
                dataProductName={reviewParams?.dataProductName?.value}
                dataProductPrice={
                  reviewParams?.dataProductPrice?.value ||
                  reviewParams?.prodottoCRM?.componentePrezzoFisso
                }
                dataProductImgUrl={reviewParams?.dataProductImgUrl?.value}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(RecensioniVerificateReviews);
export const fragment = graphql`
  fragment RecensioniVerificateReviewsFragment on LiferayRecensioniVerificateReviews {
    liferayFields {
      siteId
      articleId
    }
    showProductReviews {
      value
      content {
        prodottoCRM {
          componentePrezzoFisso
        }
        dataProductId {
          value
        }
        dataProductUrl {
          value
        }
        dataProductName {
          value
        }
        dataProductPrice {
          value
        }
        dataProductImgUrl {
          value
        }
      }
    }
  }
`;

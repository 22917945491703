import React, { useState } from "react";
import { graphql } from "gatsby";
import ProdottoSlide from "../elements/prodotto-slide";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";
import NavTabs from "../elements/nav-tabs";

const OpzioniProdotto = ({ data }) => {
  const {
    liferayFields: { articleId },
    model,
    /*idUnivoco,*/
    mainTitle,
    imageBox,
    /*tooltipAccumulo,*/
    formId,
  } = data;
  const modello = model?.value?.[0] || "OpzioniProdotto";
  const [selected, setSelected] = useState(0);

  return (
    <section
      className={classNames("section opzioni-prodotto wrapper-container", {
        "opzioni-prodotto--modello-mobile-tab": modello === "OpzioniProdottoMobileTab",
      })}
      style={{ overflowX: "hidden" }}
    >
      <div className="container">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section__title opzioni-prodotto__title"
                dangerouslySetInnerHTML={{ __html: mainTitle.value }}
              ></div>
            </div>
          </div>
        )}
        {modello === "OpzioniProdottoMobileTab" && (
          <NavTabs
            tabs={imageBox?.map((item) => ({ value: item?.content?.titleBox?.value }))}
            selectedTab={selected}
            setTab={setSelected}
            className="d-lg-none"
          />
        )}
        <div className="row">
          <div className="col-12 d-flex justify-content-center flex-wrap flex-lg-nowrap">
            {imageBox?.map((slide, j) => (
              <div
                className={classNames("opzioni-prodotto__slide-container mx-3 mb-5 mb-lg-0", {
                  "d-none d-lg-block": modello === "OpzioniProdottoMobileTab" && selected === j,
                })}
                key={j}
              >
                <ProdottoSlide
                  articleId={articleId}
                  slide={slide.content}
                  j={j}
                  image={slide.node}
                  slidesNumber={imageBox.length}
                  formId={formId}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(OpzioniProdotto);
export const fragment = graphql`
  fragment OpzioniProdottoFragment on LiferayOpzioniProdotto {
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    idUnivoco {
      value
    }
    mainTitle {
      value
    }
    imageBox {
      node {
        gatsbyImageData(width: 80, layout: FIXED)
      }
      content {
        productType {
          value
        }
        titleBox {
          value
        }
        colorBox {
          value
        }
        backgroundColorBox {
          value
        }
        radioLabel {
          value
        }
        labelBox {
          value
        }
        titleBox {
          value
        }
        descriptionBox {
          value
        }
        radioSection {
          value
        }
        lineBox {
          content {
            newSectionBox {
              value
              content {
                sectionDetail {
                  value
                }
                infoDetail {
                  value
                }
              }
            }
          }
        }
        radioPrice {
          value
        }
        priceBox {
          value
          content {
            htmlPrice {
              value
            }
            isHtml {
              value
            }
            priceDescription {
              value
              content {
                priceTooltip {
                  value
                }
              }
            }
          }
        }
        radioButton {
          value
        }
        buttonBox {
          value
        }
        newPage {
          value
        }
        linkButton {
          value
        }
        customAdvId {
          value
        }
      }
    }
    tooltipAccumulo {
      value
    }
    formId {
      value
    }
  }
`;

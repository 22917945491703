import React, { useEffect } from "react";
import { eventuallyInsertNetreviewsScript } from "../../utility/netreviews";

const NetReviewReviews = ({
  dataProductId,
  dataProductUrl,
  dataProductName,
  dataProductPrice,
  dataProductImgUrl,
}) => {
  useEffect(() => {
    eventuallyInsertNetreviewsScript();
  }, []);
  return (
    <div
      className="NETREVIEWS_PRODUCT_REVIEWS"
      data-product-id={dataProductId}
      data-product-price={dataProductPrice}
      data-product-name={dataProductName}
      data-product-url={dataProductUrl}
      data-product-img-url={dataProductImgUrl}
    ></div>
  );
};

export default NetReviewReviews;

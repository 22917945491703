import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "../link";
import infoIconWhite from "../../images/info_icon_white.png";
import infoIcon from "../../images/info_icon.png";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parseModaleComponent from "../../utility/parseModaleComponent";
import { handleContentAccessibility } from "../../utility/accessibility";

const ProdottoSlide = ({ articleId, i, slide, j, image, slidesNumber, formId, inSlider }) => {
  return (
    <div
      className={classNames("box-opzioni d-flex flex-column", {
        "box-opzioni--single": slidesNumber === 1,
        "box-opzioni--double": slidesNumber === 2,
        "box-opzioni--in-slider": inSlider,
      })}
      style={{ color: slide.backgroundColorBox?.value }}
    >
      <div
        className="box-opzioni__head d-flex justify-content-center align-items-center"
        style={{ backgroundImage: slide.colorBox?.value }}
      >
        {image && (
          <div className="box-opzioni__head-img-container">
            <GatsbyImage className="box-opzioni__head-img" image={getImage(image)} alt="icon" />
          </div>
        )}
        <div
          className="box-opzioni__title"
          dangerouslySetInnerHTML={{ __html: slide.titleBox?.value }}
        ></div>
      </div>
      <div className="box-opzioni__body flex-fill d-flex flex-column">
        <div
          className={classNames(
            "box-opzioni__description-box",
            slide.radioSection?.value !== "yes" && "mb-auto"
          )}
        >
          {handleContentAccessibility(slide.descriptionBox?.value)}
        </div>
        {slide.tooltipEtichetta?.value && slide.tooltipTesto?.value && (
          <div
            className="box-opzioni__tooltip"
            data-tip={slide.tooltipTesto?.value}
            data-for={`${articleId}-${i}-${j}`}
          >
            <span className="d-inline-flex">
              {slide.tooltipEtichetta.value}
              {slide.tooltipTesto?.value && (
                <>
                  <img
                    className="box-opzioni__tooltip-icon ml-1 d-none d-md-block"
                    src={infoIconWhite}
                    alt="icona"
                  />
                  <ReactTooltip
                    id={`${articleId}-${i}-${j}`}
                    className="tooltip"
                    place="left"
                    type="light"
                    effect="float"
                  />
                </>
              )}
            </span>
          </div>
        )}
        {slide.radioSection?.value === "yes" && (
          <>
            {slide.lineBox?.map((item, k) => (
              <div
                className="box-opzioni__line-box d-flex justify-content-center align-items-center mx-auto p-4"
                key={k}
              >
                <div className="box-opzioni__cross-wrapper">
                  <div
                    className="box-opzioni__cross box-opzioni__cross--horizontal"
                    style={{ background: slide.colorBox?.value }}
                  ></div>
                  <div
                    className="box-opzioni__cross box-opzioni__cross--vertical"
                    style={{ background: slide.colorBox?.value }}
                  ></div>
                </div>
                <div className="flex-fill">
                  <div className="box-opzioni__section-content">
                    {parseModaleComponent(item.content?.newSectionBox?.value)}
                  </div>

                  {item.content?.newSectionBox?.content?.sectionDetail?.value && (
                    <div
                      className="box-opzioni__section-detail pt-3"
                      dangerouslySetInnerHTML={{
                        __html: item.content.newSectionBox.content.sectionDetail.value,
                      }}
                    ></div>
                  )}
                  {item.content?.newSectionBox?.content?.infoDetail?.value && (
                    <div className="container-info tooltipInfo">
                      <span>
                        <img className="imagine-info-detail" alt="info" />
                      </span>
                      <span
                        className="tooltiptext"
                        dangerouslySetInnerHTML={{
                          __html: item.content?.newSectionBox?.content?.infoDetail?.value,
                        }}
                      ></span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
        {slide.radioPrice?.value === "yes" ? (
          <div className="box-opzioni__price-box mt-auto text-center">
            <div className="d-flex justify-content-center">
              {slide.priceBox?.content?.isHtml?.value &&
              slide.priceBox?.content?.htmlPrice?.value ? (
                <div
                  className="box-opzioni__price-html"
                  dangerouslySetInnerHTML={{
                    __html: slide.priceBox.content.htmlPrice.value,
                  }}
                />
              ) : (
                slide.priceBox?.value && (
                  <div className="box-opzioni__price">{slide.priceBox.value} €</div>
                )
              )}
              {slide.priceBox?.content.priceDescription?.content?.priceTooltip?.value && (
                <div
                  className="box-opzioni__price-tooltip"
                  data-tip={slide.priceBox.content.priceDescription.content.priceTooltip.value}
                  data-for={`${j}`}
                >
                  <img
                    className="box-opzioni__price-tooltip-icon ml-1 mt-2 d-none d-md-block"
                    src={infoIcon}
                    alt="info"
                  />
                  <ReactTooltip
                    id={`${j}`}
                    className="tooltip"
                    place="bottom"
                    type="light"
                    offset={{ top: 85, left: 20 }}
                    arrowColor="transparent"
                  />
                </div>
              )}
            </div>
            {slide.priceBox?.content?.priceDescription?.value && (
              <div
                className="box-opzioni__price-detail"
                dangerouslySetInnerHTML={{
                  __html: slide.priceBox.content.priceDescription.value,
                }}
              />
            )}
            {slide.radioButton?.value === "yes" && (
              <div className="mt-4">
                <Link
                  to={
                    formId?.value?.includes("form") ||
                    slide.linkButton.value.startsWith(
                      "#p_p_id_Contatti_WAR_Contattiportlet_INSTANCE_"
                    )
                      ? "#FormContattiAnchor"
                      : slide.linkButton.value
                  }
                  className="box-opzioni__btn cta-link d-inline"
                >
                  {slide.buttonBox.value}
                </Link>
              </div>
            )}
          </div>
        ) : (
          slide.radioButton?.value === "yes" && (
            <div className="text-center my-4">
              <Link
                to={
                  formId?.value?.includes("form") ||
                  slide.linkButton.value.startsWith(
                    "#p_p_id_Contatti_WAR_Contattiportlet_INSTANCE_"
                  )
                    ? "#FormContattiAnchor"
                    : slide.linkButton.value
                }
                className="box-opzioni__btn cta-link d-inline"
              >
                {slide.buttonBox.value}
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ProdottoSlide;

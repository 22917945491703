import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";
import { dataLayerPush, dataLayerStr2Obj } from "../../utility/dataLayerUtils";
import Cookies from "js-cookie";

const BottoneCtaIsolato = ({ data }) => {
  const { title, cta, ctaType, btnSubtitle } = data;
  const className = classNames(
    "mt-4 cta-link",
    `btn-color-${cta.content.ctaColore?.value || "magenta"}`,
    {
      "cta-link--secondary": ctaType?.value?.[0] === "second-cta",
    }
  );

  const startFlusso = (productName) => {
    const fluxEvent = new CustomEvent("start-flusso-switch-in", {
      detail: {
        productName,
      },
    });
    document.dispatchEvent(fluxEvent);
    dataLayerPush(dataLayerStr2Obj(cta?.content?.datalayerEvent?.value));
  };

  return (
    <section className="section bottone-cta-isolato wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section__title" dangerouslySetInnerHTML={{ __html: title.value }}></h2>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 text-center">
            {cta?.content?.nameProductSimulatore?.value ? (
              <button
                title={cta?.value}
                onClick={() => {
                  Cookies.set("simulatoreNomeProdotto", cta.content.nameProductSimulatore.value);
                  window.open("/simulatore-bolletta");
                }}
                className={className}
              >
                {cta.value}
              </button>
            ) : isFlusso(cta?.content?.ctaLink?.value) ? (
              <button
                title={cta?.value}
                className={className}
                onClick={() => startFlusso(getProductName(cta.content.ctaLink.value))}
              >
                {cta.value}
              </button>
            ) : (
              <Link
                to={
                  // eslint-disable-next-line no-script-url
                  cta?.content?.ctaLink?.value?.split("(")?.[0] === "javascript:callOnClick"
                    ? `tel:${cta?.value.replace(/ /g, "")}`
                    : cta?.content?.ctaLink?.value
                }
                title={cta?.value}
                className={className}
                onClick={() => dataLayerPush(dataLayerStr2Obj(cta?.content?.datalayerEvent?.value))}
              >
                {cta?.value}
              </Link>
            )}
          </div>
        </div>
        {btnSubtitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <h3
                className="cta-subtitle"
                dangerouslySetInnerHTML={{ __html: btnSubtitle?.value }}
              ></h3>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(BottoneCtaIsolato);
export const fragment = graphql`
  fragment BottoneCtaIsolatoFragment on LiferayBottoneCtaIsolato {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    cta {
      value
      content {
        ctaColore {
          value
        }
        nameProductSimulatore {
          value
        }
        ctaLink {
          value
        }
        datalayerEvent {
          value
        }
      }
    }
    btnSubtitle {
      value
    }
    ctaType {
      value
    }
  }
`;

import React, { Fragment, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../link";
import plusIcon from "../../images/plus_icon.png";
import minusIcon from "../../images/minus_icon.png";
import withPreview from "../../utility/with-preview";
import { throttle } from "lodash";

const Editoriale = ({ data }) => {
  const {
    titoloMenuMobile,
    sezioneTitolo,
    coloreTitoloSezione,
    coloreCta,
    coloreCtaNormaleHover,
    coloreCtaActive,
  } = data;

  const [selectedState, setSelectedState] = useState({ 0: true });
  const [openState, setOpenState] = useState({});

  useEffect(() => {
    const listener = throttle(() => {
      const scrollPoints = document.getElementsByClassName("scroll-anchor");
      Array.from(scrollPoints).forEach((span) => {
        const { top } = span.getBoundingClientRect();
        if (top < 40) {
          setSelectedState({ [span.id.slice(span.id.indexOf("-") + 1)]: true });
        }
      });
    }, 200);
    window.addEventListener("scroll", listener);
    listener();
    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <section
      className="section editoriale"
      style={{
        "--coloreCta": coloreCta?.value,
        "--coloreCtaHover": coloreCtaNormaleHover?.value,
        "--coloreCtaActive": coloreCtaActive?.value,
      }}
    >
      <div className="container-fluid">
        <div className="row d-flex flex-col flex-lg-row mx-0">
          <div className="col-lg-4 col-xl-3">
            <div className="position-lg-absolute h-100 mb-5 mb-lg-0">
              <div className="editoriale__menu-container mb-5 mb-lg-0">
                {titoloMenuMobile?.value && (
                  <div className="editoriale__menu-title d-lg-none text-uppercase">
                    {titoloMenuMobile.value}
                  </div>
                )}
                <div className="editoriale__menu">
                  {sezioneTitolo?.map(
                    (titolo, i) =>
                      titolo?.value && (
                        <div key={`menu-${i}`}>
                          <div
                            className={classNames(
                              "editoriale__menu-item d-flex align-items-center justify-content-between",
                              (selectedState[i] ||
                                (Object.keys(selectedState)[0]?.split("-")[0] === `${i}` &&
                                  !openState[i])) &&
                                "editoriale__menu-item--selected"
                            )}
                          >
                            <Link
                              to={`#sezione-${i}`}
                              className=""
                              onClick={() => setSelectedState({ [i]: true })}
                            >
                              {titolo?.value}
                            </Link>
                            {titolo?.content?.paragrafoTitolo?.length > 0 &&
                              titolo?.content?.paragrafoTitolo?.[0]?.value !== "" && (
                                <button
                                  className="editoriale__menu-icon-container"
                                  onClick={() =>
                                    setOpenState((current) => ({ ...current, [i]: !current[i] }))
                                  }
                                >
                                  <img
                                    className="editoriale__menu-icon"
                                    src={openState[i] ? minusIcon : plusIcon}
                                    alt={openState[i] ? "-" : "+"}
                                  />
                                </button>
                              )}
                          </div>
                          {titolo?.content?.paragrafoTitolo?.length > 0 &&
                            titolo?.content?.paragrafoTitolo?.[0]?.value !== "" && (
                              <div
                                className={classNames(
                                  "editoriale__menu editoriale__menu--sub d-none",
                                  {
                                    "d-block": openState[i],
                                  }
                                )}
                              >
                                {titolo.content.paragrafoTitolo.map((subitem, j) => (
                                  <div key={`menu-${i}-${j}`}>
                                    <div
                                      className={classNames(
                                        "editoriale__menu-item editoriale__menu-item--sub",
                                        {
                                          "editoriale__menu-item--selected":
                                            selectedState[`${i}-${j}`],
                                        }
                                      )}
                                    >
                                      <Link
                                        to={`#paragrafo-${i}-${j}`}
                                        onClick={() =>
                                          setSelectedState({
                                            [`${i}-${j}`]: true,
                                          })
                                        }
                                      >
                                        {subitem?.value}
                                      </Link>
                                    </div>
                                    {subitem?.content?.sottoparagrafoTitolo?.length > 0 &&
                                      subitem?.content?.sottoparagrafoTitolo?.[0]?.value !== "" && (
                                        <div className="editoriale__menu editoriale__menu--subsub">
                                          {subitem.content.sottoparagrafoTitolo.map(
                                            (subsubitem, k) => (
                                              <div
                                                className={classNames(
                                                  "editoriale__menu-item editoriale__menu-item--subsub",
                                                  {
                                                    "editoriale__menu-item--selected":
                                                      selectedState[`${i}-${j}-${k}`],
                                                  }
                                                )}
                                                key={`menu-${i}-${j}-${k}`}
                                              >
                                                <Link
                                                  to={`#sottoparagrafo-${i}-${j}-${k}`}
                                                  onClick={() =>
                                                    setSelectedState({
                                                      [`${i}-${j}-${k}`]: true,
                                                    })
                                                  }
                                                >
                                                  {subsubitem?.value}
                                                </Link>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9">
            <div className="editoriale__content d-flex flex-column text-justify">
              {sezioneTitolo?.map((titolo, i) => (
                <Fragment key={`inview-${i}`}>
                  <span className="scroll-anchor" id={`sezione-${i}`} />
                  <div className="editoriale__sezione" key={i}>
                    {titolo?.value && (
                      <h2
                        className={classNames("text-left font-weight-bold", {
                          "mb-4": !titolo?.content?.sezioneDesc?.value,
                        })}
                        style={{ color: coloreTitoloSezione?.value }}
                      >
                        {titolo.value}
                      </h2>
                    )}
                    <div
                      className={classNames("d-flex flex-column", {
                        "flex-md-row justify-content-md-between":
                          titolo?.content?.sezioneCta?.content?.posCtaSezione?.value?.[0] === "dx",
                      })}
                    >
                      {titolo?.content?.sezioneDesc?.value && (
                        <div
                          dangerouslySetInnerHTML={{ __html: titolo.content.sezioneDesc.value }}
                        />
                      )}
                      {titolo?.content?.sezioneCta?.value &&
                        titolo?.content?.sezioneCta?.content?.linkCtaSezione?.value && (
                          <div
                            className={classNames(
                              "d-flex justify-content-center justify-content-md-start mt-4 mb-2",
                              {
                                "align-items-md-center ml-md-2":
                                  titolo?.content?.sezioneCta?.content?.posCtaSezione
                                    ?.value?.[0] === "dx",
                              }
                            )}
                          >
                            <Link
                              to={titolo.content.sezioneCta.content.linkCtaSezione.value}
                              className={classNames("editoriale__cta text-center", {
                                "editoriale__cta--secondary":
                                  titolo.content.sezioneCta.content.typeCtaSezione?.value?.[0] ===
                                  "secondary-cta",
                              })}
                            >
                              {titolo.content.sezioneCta.value}
                            </Link>
                          </div>
                        )}
                      {titolo?.content?.paragrafoTitolo?.length > 0 &&
                        titolo?.content?.paragrafoTitolo?.[0]?.value !== "" &&
                        titolo.content?.paragrafoTitolo?.map((paragrafo, j) => (
                          <Fragment key={`inview-${i}-${j}`}>
                            <span id={`paragrafo-${i}-${j}`} className="scroll-anchor" />
                            <div className="editoriale__paragrafo" key={`${i}-${j}`}>
                              <div className="d-flex flex-column">
                                <div>
                                  {paragrafo?.content?.paragrafoImg?.node && (
                                    <GatsbyImage
                                      className={classNames("editoriale__img mb-2", {
                                        //default cx
                                        "float-right ml-2":
                                          paragrafo?.content?.paragrafoImg?.content?.posImgParagrafo
                                            ?.value === "dx",
                                        "float-left mr-2":
                                          paragrafo?.content?.paragrafoImg?.content?.posImgParagrafo
                                            ?.value !== "cx", //se non cx -> sx
                                      })}
                                      image={getImage(paragrafo.content.paragrafoImg.node)}
                                      alt={paragrafo?.value}
                                      style={
                                        paragrafo?.content?.paragrafoImg?.content?.posImgParagrafo
                                          ?.value !== "cx" && { maxHeight: "200px" }
                                      }
                                    />
                                  )}
                                  {paragrafo?.value && (
                                    <h3
                                      className={classNames("text-left font-weight-bold ", {
                                        "mb-3": !paragrafo?.content?.paragrafoDesc?.value,
                                      })}
                                    >
                                      {paragrafo.value}
                                    </h3>
                                  )}
                                  <div
                                    className={classNames("d-flex flex-column", {
                                      "flex-md-row justify-content-md-between":
                                        paragrafo?.content?.paragrafoCta?.content?.posCtaParagrafo
                                          ?.value?.[0] === "dx",
                                    })}
                                  >
                                    {paragrafo?.content?.paragrafoDesc?.value && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: paragrafo.content.paragrafoDesc.value,
                                        }}
                                      />
                                    )}
                                    {paragrafo?.content?.paragrafoCta?.value &&
                                      paragrafo?.content?.paragrafoCta?.content?.linkCtaParagrafo
                                        ?.value && (
                                        <div
                                          className={classNames(
                                            "d-flex justify-content-center justify-content-md-start mt-4 mb-2",
                                            {
                                              "align-items-md-center ml-md-2":
                                                paragrafo?.content?.paragrafoCta?.content
                                                  ?.posCtaParagrafo?.value?.[0] === "dx",
                                            }
                                          )}
                                        >
                                          <Link
                                            to={
                                              paragrafo.content.paragrafoCta.content
                                                .linkCtaParagrafo.value
                                            }
                                            className={classNames("editoriale__cta text-center", {
                                              "editoriale__cta--secondary":
                                                paragrafo.content.paragrafoCta.content
                                                  .typeCtaParagrafo?.value?.[0] === "secondary-cta",
                                            })}
                                          >
                                            {paragrafo.content.paragrafoCta.value}
                                          </Link>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {paragrafo?.content?.sottoparagrafoTitolo?.length > 0 &&
                              paragrafo?.content?.sottoparagrafoTitolo?.[0]?.value &&
                              paragrafo?.content.sottoparagrafoTitolo.map((sottoparagrafo, k) => (
                                <Fragment key={`inview-${i}-${j}-${k}`}>
                                  <span
                                    id={`sottoparagrafo-${i}-${j}-${k}`}
                                    className="scroll-anchor"
                                  />
                                  <div
                                    className={classNames("editoriale__sottoparagrafo", {
                                      "pb-4": sottoparagrafo?.content?.sottoparagrafoDesc?.value,
                                    })}
                                    key={`${i}-${j}-${k}`}
                                  >
                                    <div className="d-flex flex-column">
                                      <div>
                                        {paragrafo?.content?.paragrafoImg?.node && (
                                          <GatsbyImage
                                            className={classNames("editoriale__img mb-2", {
                                              //default cx
                                              "float-right ml-2":
                                                sottoparagrafo?.content?.sottoparagrafoImg?.content
                                                  ?.posImgSottoparagrafo?.value === "dx",
                                              "float-left mr-2":
                                                sottoparagrafo?.content?.sottoparagrafoImg?.content
                                                  ?.posImgSottoparagrafo?.value !== "cx", //se non cx -> sx
                                            })}
                                            image={getImage(
                                              sottoparagrafo.content.sottoparagrafoImg.node
                                            )}
                                            alt={sottoparagrafo?.value}
                                            style={
                                              sottoparagrafo?.content?.sottoparagrafoImg?.content
                                                ?.posImgSottoparagrafo?.value !== "cx" && {
                                                maxHeight: "200px",
                                              }
                                            }
                                          />
                                        )}
                                        {sottoparagrafo?.value && (
                                          <h4
                                            className={classNames(
                                              "text-left font-weight-bold font-italic",
                                              {
                                                "mb-4":
                                                  !sottoparagrafo?.content?.sottoparagrafoDesc
                                                    ?.value,
                                              }
                                            )}
                                          >
                                            {sottoparagrafo.value}
                                          </h4>
                                        )}
                                        <div
                                          className={classNames("d-flex flex-column", {
                                            "flex-md-row justify-content-md-between":
                                              sottoparagrafo?.content?.sottoparagrafoCta?.content
                                                ?.posCtaSottoparagrafo?.value?.[0] === "dx",
                                          })}
                                        >
                                          {sottoparagrafo?.content?.sottoparagrafoDesc?.value && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  sottoparagrafo.content.sottoparagrafoDesc.value,
                                              }}
                                            />
                                          )}
                                          {sottoparagrafo?.content?.sottoparagrafoCta?.value &&
                                            sottoparagrafo?.content?.sottoparagrafoCta?.content
                                              ?.linkCtaSottoparagrafo?.value && (
                                              <div
                                                className={classNames(
                                                  "d-flex justify-content-center justify-content-md-start mt-4 mb-2",
                                                  {
                                                    "align-items-md-center ml-md-2":
                                                      sottoparagrafo?.content?.sottoparagrafoCta
                                                        ?.content?.posCtaSottoparagrafo
                                                        ?.value?.[0] === "dx",
                                                  }
                                                )}
                                              >
                                                <Link
                                                  to={
                                                    sottoparagrafo.content.sottoparagrafoCta.content
                                                      .linkCtaSottoparagrafo.value
                                                  }
                                                  className={classNames(
                                                    "editoriale__cta text-center",
                                                    {
                                                      "editoriale__cta--secondary":
                                                        sottoparagrafo.content.sottoparagrafoCta
                                                          .content.typeCtaSottoparagrafo
                                                          ?.value?.[0] === "secondary-cta",
                                                    }
                                                  )}
                                                >
                                                  {sottoparagrafo.content.sottoparagrafoCta.value}
                                                </Link>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ))}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Editoriale);
export const fragment = graphql`
  fragment EditorialeFragment on LiferayEditoriale {
    liferayFields {
      siteId
      articleId
    }
    sezioneTitolo {
      value
      content {
        sezioneDesc {
          value
        }
        sezioneCta {
          value
          content {
            linkCtaSezione {
              value
            }
            posCtaSezione {
              value
            }
            typeCtaSezione {
              value
            }
          }
        }
        paragrafoTitolo {
          value
          content {
            paragrafoDesc {
              value
            }
            paragrafoImg {
              node {
                gatsbyImageData(width: 400)
              }
              content {
                posImgParagrafo {
                  value
                }
              }
            }
            paragrafoCta {
              value
              content {
                linkCtaParagrafo {
                  value
                }
                posCtaParagrafo {
                  value
                }
                typeCtaParagrafo {
                  value
                }
              }
            }
            sottoparagrafoTitolo {
              value
              content {
                sottoparagrafoDesc {
                  value
                }
                sottoparagrafoImg {
                  node {
                    publicURL
                  }
                  content {
                    posImgSottoparagrafo {
                      value
                    }
                  }
                }
                sottoparagrafoCta {
                  value
                  content {
                    linkCtaSottoparagrafo {
                      value
                    }
                    posCtaSottoparagrafo {
                      value
                    }
                    typeCtaSottoparagrafo {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    titoloMenuMobile {
      value
    }
    coloreTitoloSezione {
      value
    }
    coloreCta {
      value
    }
    coloreCtaNormaleHover {
      value
    }
    coloreCtaActive {
      value
    }
  }
`;

import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Icon from "../elements/icon-svg";

const SocialShare = ({ location, title }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const facebookShareUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.set("u", `${siteMetadata.siteUrl}${location.pathname}`);
    if (title) {
      params.set("t", title);
    }
    return `https://www.facebook.com/sharer.php?${params.toString()}`;
  }, [siteMetadata, title, location]);

  const twitterShareUrl = useMemo(() => {
    const params = new URLSearchParams();
    if (title) {
      params.set("status", `${title} ${siteMetadata.siteUrl}${location.pathname}`);
    } else {
      params.set("status", `${siteMetadata.siteUrl}${location.pathname}`);
    }
    return `https://twitter.com/home?${params.toString()}`;
  }, [siteMetadata, title, location]);

  const linkedinShareUrl = useMemo(() => {
    const params = new URLSearchParams();
    params.set("url", `${siteMetadata.siteUrl}${location.pathname}`);
    return `http://www.linkedin.com/cws/share?${params.toString()}`;
  }, [siteMetadata, location]);

  return (
    <article id="social-item-box" className="social-share wrapper-container">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-start align-items-center">
            <div className="social-share__title">Condividi</div>
            <ul className="social-share__list d-flex align-items-center m-0 pl-4">
              <li className="social-share__item">
                <a
                  href={facebookShareUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="social-share__link"
                  id="share-fb"
                  aria-label="Condividi via Facebook"
                >
                  <Icon name="facebook-solid" />
                </a>
              </li>
              <li className="social-share__item">
                <a
                  href={twitterShareUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="social-share__link"
                  id="share-tw"
                  aria-label="Condividi via Twitter"
                >
                  <Icon name="twitter-x" />
                </a>
              </li>
              <li className="social-share__item">
                <a
                  href={linkedinShareUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="social-share__link"
                  id="share-in"
                  aria-label="Condividi via LinkedIn"
                >
                  <Icon name="linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SocialShare;

import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SocialShare from "../elements/social-share";
import withPreview from "../../utility/with-preview";

const EditorialeGenerico = ({ data, location, title }) => {
  const { articolo, nascondisocial, mostraCta } = data;
  return (
    <section className="section editoriale-generico">
      {articolo.map(
        (itemArticolo, i) =>
          (itemArticolo?.content?.titolo?.value ||
            itemArticolo?.content?.corpo?.value ||
            itemArticolo?.content?.sezioneimmagine?.value) && (
            <article id="article" className="wrapper-container" key={i}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {(itemArticolo?.content?.titolo?.value ||
                      itemArticolo.content?.corpo?.value) && (
                      <div className="editoriale-generico__bar"></div>
                    )}
                    {itemArticolo?.content?.titolo?.value && (
                      <h2 className="editoriale-generico__article-title">
                        {itemArticolo.content.titolo.value}
                      </h2>
                    )}
                    <div
                      className="editoriale-generico__body"
                      dangerouslySetInnerHTML={{ __html: itemArticolo?.content?.corpo?.value }}
                    ></div>
                    {itemArticolo?.content?.sezioneimmagine?.content?.immagine?.node && (
                      <div className="editoriale-generico__image">
                        <GatsbyImage
                          image={getImage(
                            itemArticolo.content.sezioneimmagine.content.immagine.node
                          )}
                          alt=""
                        />
                        {itemArticolo?.sezioneimmagine?.content?.didascaliaimmagine?.value && (
                          <div className="editoriale-generico__caption">
                            {itemArticolo.sezioneimmagine.didascaliaimmagine.value}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </article>
          )
      )}

      {mostraCta?.value && (
        <article id="CTA-per-te-box">
          {/* <style type="text/css">
        .div-dim1 {
            width:285px;
            max-width:285px;
            max-height:70px;
        }
        
        .div-dim2 {
            width:430px;
            max-width:430px;
            max-height:70px;
        }
        
        
        .div-margin {
            margin-left:80px!important;
        }
        
        .bg-blue-day {
            background-color: #03acff;
        }
        
        .padding-tasto-right {
            padding: 10px 50px;
        }
        
        @media (max-width: 768px) {
            .flex-center-mobile {
                margin:0px!important;
            }
            .bg-none {
                padding:0px!important;
            }
            .padding-tasto-right {
                padding: 15px!important;
            }
            .div-margin {
                margin-left:0px!important;
            }
            .div-dim1 {
                width:100%;
                max-width:100%;
            }
            .div-dim2 {
                width:100%;
                max-width:100%;
            }
            
            .absolute-right-m {
                margin: auto !important;
                position: absolute !important;
                right: 0;
            }
        }
    </style> */}

          <section className="wrap row bg-none flex-center">
            <div className="text-center wrap row flex-stretch">
              <div
                className="div-dim2 no-padding bg-blue-day white flex-end"
                id="div_CTA_accediId"
                style={{ display: "none", marginBottom: "5px" }}
              >
                <div className="wrap row display-mobile padding-tasto-right flex-center flex-center-mobile">
                  {/* <a data-senna-off="true" href="#" onclick="javascript:setCTAHrefURL('login')" className="strong white
                        no-underline flex-start medium-text relative">SPAZIO CLIENTI<span className="btn-more
                        head-small fas white absolute-right-m"></span></a> */}
                </div>
              </div>
            </div>
            <div className="text-center wrap row flex-stretch">
              <div
                className="div-dim1 no-padding bg-blue-day white flex-end"
                id="div_CTA_engieFitId"
                style={{ display: "none", marginBottom: "5px" }}
              >
                <div className="wrap row display-mobile padding-tasto-right flex-center flex-center-mobile">
                  {/* <a data-senna-off="true" href="#" onclick="javascript:location.href='/in-evidenza/engie-fit'" className="strong white
                        no-underline flex-start medium-text relative">ENGIE FIT<span className="btn-more head-small fas
                        white absolute-right-m"></span></a> */}
                </div>
              </div>

              <div
                className="div-dim1 no-padding bg-blue-day white flex-end div-margin"
                id="div_CTA_domuscheckId"
                style={{ display: "none", marginBottom: "5px" }}
              >
                <div className="wrap row display-mobile padding-tasto-right flex-center flex-center-mobile">
                  {/* <a data-senna-off="true" href="#" onclick="javascript:location.href='/in-evidenza/domus-check'" className="strong
                        white no-underline flex-start medium-text relative">DOMUSCHECK<span className="btn-more head-small fas
                        white absolute-right-m"></span></a> */}
                </div>
              </div>
            </div>
            <div className="text-center wrap row flex-stretch">
              <div
                className="div-dim1 no-padding bg-blue-day white flex-end"
                id="div_CTA_contattaciId"
                style={{ display: "none", marginBottom: "5px" }}
              >
                <div className="wrap row display-mobile padding-tasto-right flex-center flex-center-mobile">
                  {/* TODO: fix link */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    data-senna-off="true"
                    href="#"
                    // onclick="javascript:setCTAHrefURL('contact')"
                    className="strong white
                        no-underline flex-start medium-text relative"
                  >
                    CONTATTACI
                    <span
                      className="btn-more head-small fas
                        white absolute-right-m"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center wrap row flex-stretch">
              <div
                className="div-dim2 m-all bg-blue-day no-padding white flex-end"
                id="loginDivId"
                style={{ display: "none" }}
              >
                <div className="wrap row display-mobile padding-tasto-right flex-center flex-center-mobile">
                  {/* <a data-senna-off="true" href="#" id="authtendril" className="strong white no-underline flex-start medium-text relative">
                        ACCEDI AL PORTALE<span className="btn-more head-small fas white absolute-right-m"></span></a> */}
                </div>
              </div>
            </div>
          </section>
        </article>
      )}

      {!nascondisocial.value && <SocialShare location={location} title={title} />}
    </section>
  );
};

export default withPreview(EditorialeGenerico);
export const fragment = graphql`
  fragment EditorialeGenericoFragment on LiferayEditorialeGenerico {
    liferayFields {
      siteId
      articleId
    }
    articolo {
      value
      content {
        titolo {
          value
        }
        corpo {
          value
        }
        sezioneimmagine {
          value
          content {
            immagine {
              node {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            didascaliaimmagine {
              value
            }
          }
        }
        sezionevideo {
          value
          content {
            urlvideo {
              value
            }
            didascaliavideo {
              value
            }
          }
        }
        sezionegalleria {
          value
          content {
            immaginegalleria {
              node {
                id
                publicURL
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
    nascondisocial {
      value
    }
    mostraCta {
      value
    }
  }
`;
